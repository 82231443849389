import React, { useEffect, useState } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import { IProduct } from '@kilornd/api-wrapper/dist/types';
import styled from 'styled-components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';
import {
  Box,
  Container,
  ContainerSmall,
  ContentFrameSmall,
  GridWrapper,
  H1,
  H5,
  Regular,
  Small,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';

import { Image, SectionWrapper } from 'components';
import { updateSelectedPlan } from 'state/user/effects';
import { updateDiscountProduct } from 'state/funnel/effects';
import { AppState } from 'state/types';
import { blue, secondary } from 'styles/colors';

import { PlanCard } from '../elements/PlanCard';
import { PlansInclude } from '../elements/PlansInclude';

interface Props {
  isBottomPlans?: boolean;
  onClick: () => void;
}

export const Plans: React.FC<Props> = ({ isBottomPlans = false, onClick }) => {
  const { isMobile } = useQuery();
  const { products } = useSelector((s: AppState) => s.funnel);
  const [selectedKey, setSelectedKey] = useState('one-time-purchase');
  const dispatch = useDispatch();

  useEffect(() => {
    const product = products.find(
      (p: IProduct) => p.key === 'one-time-purchase',
    );

    if (product) {
      dispatch(updateSelectedPlan(product));
      dispatch(updateDiscountProduct(product));
    }
  }, [dispatch, products]);

  const handleSelect = (product: IProduct) => {
    if (product) {
      dispatch(updateSelectedPlan(product));
    }
    setSelectedKey(product.key);
  };

  return (
    <SectionWrapper>
      <Container>
        {!isBottomPlans && (
          <>
            <H1
              margin={isMobile ? '0 0 1rem' : '0 0 1.5rem'}
              textAlign={!isMobile && 'center'}
            >
              Stop smoking with a personalized{' '}
              <TextWrapper color={blue}>100-day quitting program</TextWrapper>
            </H1>
            <Regular
              margin={isMobile ? '0 0 2.5rem' : '0 0 4rem'}
              textAlign={!isMobile && 'center'}
            >
              After 100 days, you will be completely smoke-free, save hundreds
              of dollars, and finally achieve your long-term goals.
            </Regular>
          </>
        )}
      </Container>
      <ContainerSmall>
        <GridWrapper
          columns={isMobile ? 1 : 2}
          gridGap="0 2rem"
          justifyItems="space-around"
        >
          <PlansContentFrameSmall margin="0 0 2rem">
            <H5Styled>Select the billing option</H5Styled>
            <Box margin="0 0 0.5rem">
              <PlanCard
                productKey="one-time-purchase"
                onSelect={handleSelect}
                selectedKey={selectedKey}
                isBottomPlans={isBottomPlans}
                onButtonClick={onClick}
                isBestValueOffer
              />
            </Box>
            <Box margin="0 0 0.5rem">
              <PlanCard
                productKey="3-month-plan"
                onSelect={handleSelect}
                selectedKey={selectedKey}
                isBottomPlans={isBottomPlans}
                onButtonClick={onClick}
                isPopularOffer
                tooltip={
                  <>
                    You can purchase the program with a 3-month subscription,
                    paying the total amount in 3 installments.{' '}
                    <strong>
                      You will be billed every month for 3 months until you
                      complete the entire 100-day program.
                    </strong>
                    <br />
                    <br />
                    You can cancel the subscription at any time.
                  </>
                }
              />
            </Box>
            <Small textAlign="center" margin="0 1.5rem 1rem">
              By choosing your plan you agree to the{' '}
              <Link to="/terms-of-services">T&amp;Cs</Link> and{' '}
              <Link to="/privacy-policy">Privacy Policy</Link>.
            </Small>
            <Image src="safe_checkout" />
          </PlansContentFrameSmall>
          <ContentFrameSmall margin="0">
            <H5Styled>What’s in my program?</H5Styled>
            <PlansInclude />
          </ContentFrameSmall>
        </GridWrapper>
      </ContainerSmall>
    </SectionWrapper>
  );
};

const H5Styled = styled(H5).attrs({
  margin: '0 0 1rem',
})`
  text-transform: uppercase;
`;

const PlansContentFrameSmall = styled(ContentFrameSmall)`
  justify-self: right;
`;

const Link = styled(GatsbyLink)`
  color: ${secondary};
`;
