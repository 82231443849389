import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';
import {
  ContainerSmall,
  GridWrapper,
  H3,
  H5,
} from '@kilornd/modules-wrapper/es/components';

import { CollapsibleItem, SectionWrapper } from 'components';
import { neutral100, white } from 'styles/colors';

const FAQ = [
  {
    question: 'What happens after I order?',
    answer: (
      <>
        After you place your order, we get to work! Based on your quiz answers,
        we’ll craft your personal program for quitting. You will receive an
        email with all the necessary info to begin your{' '}
        <strong>ZeroSmokes</strong> journey.
      </>
    ),
  },
  {
    question: 'Is this a one-time payment?',
    answer: (
      <>
        You can choose the billing option which is more appropriate for you. You
        can purchase the <strong>ZeroSmokes</strong> program as a one-time
        purchase or choose to pay in 3 monthly installments.
      </>
    ),
  },
  {
    question: 'Where can I access my plan?',
    answer: (
      <>
        Your plan will be accessible in the member’s area and enrolled in the
        email sequence for daily motivation tips and tricks. Moreover, you will
        receive access to the social media group of the{' '}
        <strong>ZeroSmokes</strong> community.
      </>
    ),
  },
  {
    question: 'Why is this program paid?',
    answer: (
      <>
        We are aiming to offer our users the best experience, which comes with a
        lot of work. The entire program is developed by a large team of
        behavioral science experts who work long hours to prepare effective,
        trustworthy, and enjoyable content and plans to help you quit. The
        program is also completely free of ads and is constantly being updated
        based on your feedback.
      </>
    ),
  },
];

export const Faq: React.FC = () => {
  const { isMobile } = useQuery();

  const [selected, setSelected] = useState<number | null>(null);

  const handleCollapse = (questionId: number) => {
    if (questionId === selected) setSelected(null);
    else setSelected(questionId);
  };

  return (
    <SectionWrapper>
      <ContainerSmall margin={isMobile ? '2.5rem auto' : '4rem auto'}>
        <H5Styled textAlign={!isMobile && 'center'}>
          Frequently Asked Questions
        </H5Styled>
        <GridWrapper gridGap="0.5rem">
          {FAQ.map(({ answer, question }, index) => (
            <DropdownWrapper key={index}>
              <CollapsibleItem
                title={question}
                onClick={() => handleCollapse(index)}
                isSelected={selected === index}
              >
                {answer}
              </CollapsibleItem>
            </DropdownWrapper>
          ))}
        </GridWrapper>
      </ContainerSmall>
    </SectionWrapper>
  );
};

const DropdownWrapper = styled.div`
  padding: 1rem;
  background-color: ${white};
  border-radius: 0.5rem;
  border: 1px solid ${neutral100};
`;

const H5Styled = styled(H5).attrs({
  margin: '0 0 1rem',
})`
  text-transform: uppercase;
`;
