import React from 'react';

import { useRouter } from 'apis/history';
import { Element, scroller } from 'react-scroll';

import { Plans } from './sections/Plans';
import { Faq } from './sections/Faq';
import { Testimonials } from './sections/Testimonials';
import { YouWillLove } from './sections/YouWillLove';
import { EasyToFollow } from './sections/EasyToFollow';
import { InfoCards } from './sections/InfoCards';

const Checkout: React.FC = React.memo(() => {
  const { goToPayments } = useRouter();

  const handleScroll = (section: string = 'page-end') => {
    scroller.scrollTo(section, {
      spy: true,
      smooth: true,
      duration: 800,
    });
  };

  return (
    <>
      <Element id="plans">
        <Plans onClick={goToPayments} />
      </Element>
      <Testimonials />
      <YouWillLove onClick={() => handleScroll('plans')} />
      <EasyToFollow />
      <InfoCards />
      <Faq />
      <Plans onClick={goToPayments} isBottomPlans />
    </>
  );
});

Checkout.displayName = 'Checkout';

export default Checkout;
