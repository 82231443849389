import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { IProduct } from '@kilornd/api-wrapper/dist/types';
import {
  Box,
  Caption,
  FlexWrapper,
  H3,
  H5,
  Regular,
  Small,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';
import { mobile } from '@kilornd/modules-wrapper/es/styles/breakpoints';

import { AppState } from 'state/types';
import { accent, grey200, secondary, warning, white } from 'styles/colors';
import { PrimaryButton, Svg, Tooltip } from 'components';
import {
  calculateDiscountProc,
  calculatePricePerDay,
  getBillingDescription,
  getProductFinalPrice,
  getProductOriginalPrice,
} from 'utils/prices';

type Props = {
  productKey: string;
  selectedKey: string;
  onSelect: (product: IProduct) => void;
  isBottomPlans?: boolean;
  isPopularOffer?: boolean;
  isBestValueOffer?: boolean;
  onButtonClick: () => void;
  tooltip?: JSX.Element | string;
};

export const PlanCard = ({
  productKey,
  selectedKey,
  onSelect,
  isBottomPlans,
  isPopularOffer,
  isBestValueOffer,
  onButtonClick,
  tooltip,
}: Props) => {
  const { products } = useSelector((s: AppState) => s.funnel);
  const product: IProduct | undefined = products.find(
    (p: IProduct) => p.key === productKey,
  );

  const selected = selectedKey === productKey;
  const finalPrice = getProductFinalPrice(product);
  const originalPrice = getProductOriginalPrice(product);
  const pricePerDay = calculatePricePerDay(product);
  const billingDesription = getBillingDescription(product);

  return !product ? null : (
    <Card
      id={`product--select${isBottomPlans ? '-bottom' : ''}`}
      data-testid={`product--select${isBottomPlans ? '-bottom' : ''}`}
      selected={selected}
    >
      {tooltip && (
        <TooltipWrapper>
          <Tooltip text={tooltip}>
            <Svg src="help_outline" />
          </Tooltip>
        </TooltipWrapper>
      )}
      <Box onClick={() => onSelect(product)}>
        <FlexWrapper>
          <H5>{product?.name}</H5>
          {isPopularOffer && (
            <PopularLabel>
              <Caption fontWeight={700}>Popular</Caption>
            </PopularLabel>
          )}
          {isBestValueOffer && (
            <BestValueLabel>
              <Star src="star" />
              <Caption fontWeight={700} color={white}>
                Best value
              </Caption>
            </BestValueLabel>
          )}
        </FlexWrapper>
        <FlexWrapper alignItems="baseline" margin="1rem 0 0.25rem">
          <H3Styled margin="0 0.25rem 0 0">${pricePerDay}</H3Styled>
          <Regular>/ day</Regular>
        </FlexWrapper>
        <FlexWrapper alignItems="baseline" margin="0 0 0.5rem">
          {finalPrice != originalPrice && (
            <Small margin="0 0.25rem 0 0">
              <TextWrapper textDecoration="line-through">
                ${originalPrice}
              </TextWrapper>
            </Small>
          )}
          <Small margin="0 0.25rem 0 0">
            <TextWrapper fontWeight="700">${finalPrice}</TextWrapper>{' '}
            {billingDesription}
          </Small>
        </FlexWrapper>
        <PrimaryButton
          margin="1rem 0 0 "
          onClick={onButtonClick}
          id={`product_${product.key}__buy-button${
            isBottomPlans ? '-bottom' : ''
          }`}
          data-testid={`product_${product.key}__buy-button${
            isBottomPlans ? '-bottom' : ''
          }`}
        >
          Start Now (Save {calculateDiscountProc(product)?.toFixed(0)}%)
        </PrimaryButton>
      </Box>
    </Card>
  );
};

const Star = styled(Svg)`
  height: 0.625rem;
  width: 0.625rem;
  margin: 0 0.25rem 0 0;

  path {
    fill: ${white};
  }
`;

const Card = styled.div<{ selected: boolean }>`
  position: relative;
  padding: 1rem;
  background-color: ${white};
  border: 0.125rem solid ${({ selected }) => (selected ? secondary : grey200)};
  border-radius: 1rem;
  cursor: pointer;
  margin: 0 0 1rem;
`;

const PopularLabel = styled(FlexWrapper).attrs({
  alignItems: 'center',
  padding: '0.1875rem 0.5rem',
  margin: '0 0 0 0.5rem',
})`
  background-color: ${accent};
  border-radius: 0.375rem;
`;

const BestValueLabel = styled(PopularLabel)`
  background-color: ${warning};
`;

const H3Styled = styled(H3).attrs({
  fontWeight: '700',
})`
  @media ${mobile} {
    font-size: 1.5rem;
  }
`;

const TooltipWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1rem;
`;
