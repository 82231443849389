import React from 'react';
import styled from 'styled-components';

import { Container, H3, H5 } from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';
import { Image, SectionWrapper, Slider, Svg } from 'components';

import { blue } from 'styles/colors';

const LIST = [
  {
    image: 'easy_to_follow_1',
    icon: 'assignment_ind',
    text: 'Follow your personalized step-by-step quitting program',
  },
  {
    image: 'easy_to_follow_2',
    icon: 'mail',
    text: 'Get daily motivation and stay accountable',
  },
  {
    image: 'easy_to_follow_3',
    icon: 'question_answer',
    text: 'Join a supportive community of quitters like you ',
  },
] as const;

export const EasyToFollow: React.FC = () => {
  const { isMobile } = useQuery();

  const sliderSettings = {
    arrows: false,
    dots: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: '55rem',
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const stepList = LIST.map(({ text, icon, image }) => (
    <Card key={icon}>
      <ImageWrapper>
        <Image src={image} />
      </ImageWrapper>
      <Icon src={icon} />
      <H3 margin="1rem 0 0" color={blue}>
        {text}
      </H3>
    </Card>
  ));

  return (
    <>
      <SectionWrapperHeadline>
        <Container>
          <H5Styled
            margin={isMobile ? '0 0 1rem' : '0 0 1.5rem'}
            textAlign={!isMobile && 'center'}
          >
            Easy-to-follow online quitting program
          </H5Styled>
        </Container>
      </SectionWrapperHeadline>
      <SectionWrapperContent hasBackground>
        <Container>
          <SliderWrapper>
            <Slider sliderSettings={sliderSettings}>{stepList}</Slider>
          </SliderWrapper>
        </Container>
      </SectionWrapperContent>
    </>
  );
};

const SectionWrapperHeadline = styled(SectionWrapper)`
  margin-bottom: 0;
`;

const SectionWrapperContent = styled(SectionWrapper)`
  margin: 0;
  padding: 1.5rem 0 2rem;

  @media ${mobile} {
    padding: 1rem 0 1.5rem;
  }
`;

const Card = styled.div`
  text-align: center;
  height: 100%;
`;

const SliderWrapper = styled.div`
  .slick-list {
    margin: 0 -1rem -3rem;
    padding: 0 0 3rem;
  }

  .slick-slide {
    padding: 0 2rem;
  }
`;

const H5Styled = styled(H5).attrs({
  margin: '0 0 1rem',
})`
  text-transform: uppercase;
`;

const ImageWrapper = styled.div`
  max-height: 21.5rem;
  max-width: 21rem;
  margin: 0 auto;
`;

const Icon = styled(Svg)`
  margin: 0.5rem 0 0;
  width: 5.25rem;
  height: 5.25rem;
  path {
    fill: ${blue};
  }
`;
