import React from 'react';
import styled from 'styled-components';

import {
  ContainerSmall,
  ContentFrameSmall,
  GridWrapper,
  H5,
  ListItem,
} from '@kilornd/modules-wrapper/es/components';
import { PrimaryButton, SectionWrapper, Svg } from 'components';

import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';

const LIST = [
  <>
    <strong>You want to stop smoking completely</strong> without intense and
    exhausting withdrawal symptoms.
  </>,
  <>
    You have <strong>tried to quit before but went back</strong> to your old
    habits.
  </>,
  <>
    <strong>Smoke-free life is your long-term goal</strong>, although sometimes
    you feel the lack of willpower to do it.
  </>,
  <>
    You want to learn how to <strong>socialize without smoking</strong>.
  </>,
  <>
    <strong>You think that there’s no solution that fits all</strong>, so you
    seek a personalized program.
  </>,
  <>
    <strong>You want to feel like you’re gaining a new approach to life</strong>{' '}
    instead of losing an old habit.{' '}
  </>,
];

interface Props {
  onClick: () => void;
}

export const YouWillLove: React.FC<Props> = ({ onClick }) => {
  const { isMobile } = useQuery();

  return (
    <SectionWrapper>
      <ContainerSmall>
        <H5Styled
          margin={isMobile ? '0 0 1rem' : '0 0 1.5rem'}
          textAlign={!isMobile && 'center'}
        >
          You will love ZeroSmokes if...
        </H5Styled>
        <GridWrapper
          columns={isMobile ? 1 : 2}
          gridGap="0 2rem"
          alignItems="center"
        >
          {LIST.map((text, index) => (
            <ListItem
              key={index}
              title={text}
              icon={<Svg src="check" />}
              iconSize="1.5rem"
              as="li"
              alignItems="flex-start"
              margin={isMobile ? '0 0 1rem' : '0 0 1.5rem'}
            />
          ))}
        </GridWrapper>
        <ContentFrameSmall>
          <PrimaryButton
            margin={isMobile ? '1.5rem 0 2.5rem' : '0.5rem 0 4rem'}
            onClick={onClick}
          >
            Get my plan
          </PrimaryButton>
        </ContentFrameSmall>
      </ContainerSmall>
    </SectionWrapper>
  );
};

const H5Styled = styled(H5).attrs({
  margin: '0 0 1rem',
})`
  text-transform: uppercase;
`;
