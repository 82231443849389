import React from 'react';
import styled from 'styled-components';

import { GridWrapper, ListItem } from '@kilornd/modules-wrapper/es/components';
import { Svg } from 'components';

const PLAN_BENEFITS = [
  {
    icon: 'assignment_ind',
    title: 'Personalized quitting plan',
    text: 'Unique quitting system with over 600+ practical methods to overcome your triggers',
  },
  {
    icon: 'playlist_add_check',
    title: 'Daily support for 100 days',
    text: 'To change a habit, people need around 3 months – and we will be with you every step of the way',
  },
  {
    icon: 'event_note',
    title: 'Progress tracking and accountability',
    text: 'Review your progress, earn achievements, and keep yourself motivated',
  },
  {
    icon: 'group_add',
    title: 'Supportive community',
    text: 'Fellow ZeroSmokers to inspire and get inspired ',
  },
  {
    icon: 'question_answer',
    title: 'Tips to deal with social pressure',
    text: 'Master the skill of avoiding social pressure to smoke and stay confident with your decision ',
  },
];

export const PlansInclude: React.FC = () => (
  <List>
    {PLAN_BENEFITS.map(({ title, text, icon }, index) => (
      <ListItem
        alignItems="flex-start"
        key={index}
        as="li"
        icon={<Svg src={icon} width="1.5rem" height="1.5rem" />}
        iconSize="1.5rem"
        title={title}
        subtitle={text}
      />
    ))}
  </List>
);

const List = styled(GridWrapper).attrs({
  as: 'ul',
})`
  grid-gap: 0.5rem;
`;
