import React from 'react';
import styled from 'styled-components';

import {
  Box,
  Caption,
  Container,
  FlexWrapper,
  H5,
  Small,
  TextWrapper,
} from '@kilornd/modules-wrapper/es/components';
import { useQuery } from '@kilornd/modules-wrapper/es/styles/breakpoints';
import { SectionWrapper, Slider, Svg } from 'components';

import {
  blue,
  black,
  convertHexToRGB,
  neutral500,
  primary,
  white,
} from 'styles/colors';

const LIST = [
  {
    title: 'Carol, 31',
    subtitle: 'Smoke-free for 68 days',
    description:
      'I finally quit smoking after 13 years. It is SO easy if you follow all the steps in the program! Thank you so much, this is the best gift I gave to myself.',
  },
  {
    title: 'Anne, 45',
    subtitle: 'Smoke-free for 94 days',
    description:
      'It’s a huge relief to be free, but it’s even better to be free with what seemed like minimal effort. I’m no longer feeling the need to smoke for over 3 months!',
  },
  {
    title: 'Dennis, 38',
    subtitle: 'Smoke-free for 18 days',
    description:
      'Finally quit! Feel elated and energized. I don’t think I’ll ever touch a cigarette again.',
  },
];

export const Testimonials: React.FC = () => {
  const { isMobile } = useQuery();

  const sliderSettings = {
    arrows: false,
    dots: false,
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: '55rem',
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const testimonialList = LIST.map(
    ({ title, subtitle, description }, index) => (
      <Card key={index}>
        <FlexWrapper justifyContent="space-between">
          <H5 margin="0">{title}</H5>
          <FlexWrapper alignItems="center">
            <CheckIcon src="check" />
            <Small color={blue} fontWeight="700">
              Verified customer
            </Small>
          </FlexWrapper>
        </FlexWrapper>
        <Caption color={neutral500}>{subtitle}</Caption>
        <FlexWrapper margin="1rem 0">
          <Star src="star" />
          <Star src="star" />
          <Star src="star" />
          <Star src="star" />
          <Star src="star" />
        </FlexWrapper>
        {description}
      </Card>
    ),
  );

  return (
    <SectionWrapper>
      <Container>
        <H5Styled margin="0 0 0.5rem" textAlign={isMobile ? 'left' : 'center'}>
          Join over <TextWrapper color={blue}>72,358</TextWrapper> happy
          ZeroSmokers
        </H5Styled>
        <SliderWrapper>
          <Slider sliderSettings={sliderSettings}>{testimonialList}</Slider>
        </SliderWrapper>
      </Container>
    </SectionWrapper>
  );
};

const Card = styled(Box)`
  padding: 1rem;
  background-color: ${white};
  border-radius: 1rem;
  box-shadow: 0 1rem 2rem ${convertHexToRGB(black, 0.08)};
`;

const Star = styled(Svg)`
  margin: 0 0.25rem 0 0;
  width: 1.25rem;
  height: 1.25rem;
`;

const SliderWrapper = styled.div`
  .slick-list {
    margin: 0 -1rem -3rem;
    padding: 0 0 3rem;
  }

  .slick-slide {
    padding: 0 1rem;

    :first-of-type {
      padding: 0 1rem 0 2rem;
    }
    :last-of-type {
      padding: 0 2rem 0 1rem;
    }
  }
`;

const H5Styled = styled(H5).attrs({
  margin: '0 0 1rem',
})`
  text-transform: uppercase;
`;

const CheckIcon = styled(Svg)`
  color: ${primary};
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0.25rem 0 0;
`;
