import React from 'react';
import styled from 'styled-components';

import {
  Box,
  ContainerSmall,
  GridWrapper,
  H4,
  Regular,
} from '@kilornd/modules-wrapper/es/components';
import {
  mobile,
  useQuery,
} from '@kilornd/modules-wrapper/es/styles/breakpoints';
import { Image, SectionWrapper } from 'components';

import { black, convertHexToRGB, white } from 'styles/colors';

export const InfoCards: React.FC = () => {
  const { isMobile } = useQuery();

  return (
    <SectionWrapper>
      <ContainerSmall>
        <GridWrapper
          columns={isMobile ? 1 : 2}
          gridGap="2rem"
          alignItems="start"
        >
          <Card>
            <Image src="learn_how_to_deal" margin="0 0 1rem" />
            <H4 margin="0 0 1rem">
              Learn how to deal with your triggers without smoking
            </H4>
            <Regular>
              Find out hundreds of tips on how to deal with intense emotions,
              what activities could be replaced instead of smoking, and how to
              organize your room or working area. Easy to implement on the go.
            </Regular>
          </Card>
          <Card>
            <Image src="change_the_way" margin="0 0 1rem" />
            <H4 margin="0 0 1rem">
              Change the way you perceive smoking completely
            </H4>
            <Regular margin="0 0 1rem">
              Change your thought patterns so that new, healthy habits naturally
              replace older, destructive ones.
            </Regular>
            <Regular>
              ZeroSmokes’ unique quitting methodology focuses on developing new
              ways of thinking. You will learn how to recognize your thought
              patterns, increase your confidence in your ability to quit, and
              learn to respond to your triggers differently.
            </Regular>
          </Card>
        </GridWrapper>
      </ContainerSmall>
    </SectionWrapper>
  );
};

const Card = styled(Box).attrs({
  padding: '1.5rem',
  background: `${white}`,
  borderRadius: '1rem',
  boxShadow: `0 1rem 2rem ${convertHexToRGB(black, 0.04)}`,
})`
  @media ${mobile} {
    box-shadow: 0 0 0.25rem ${convertHexToRGB(black, 0.16)};
  }
`;
